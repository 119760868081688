<template>
  <div class="orderErrorLogsList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(orderErrorLogs)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(orderErrorLogs)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="dateCell"
          slot-scope="{props}"
        >
          <td>
            {{ dateTime_dateTimeSeconds(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <template
          slot="messageCell"
          slot-scope="{props}"
        >
          <td
            style="white-space: pre-line; text-align: initial;"
            v-html="props.dataItem.message.length < 2000 ? kgm_getNestedValue(props.field, props.dataItem) :
              `${kgm_getNestedValue(props.field, props.dataItem).substring(0, 1999)}...`"
          />
        </template>
        <div
          slot="timeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="timeFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('timeFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        
        <div
          slot="messageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="messageFilterValue"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('messageFilterValue', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "OrderErrorLogsList",
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      orderErrorLogs: null,
      kgm_columns: [
        {
          field: 'time',
          filterable: true,
          filter: 'text',
          title: this.$t('date'),
          filterCell: 'timeFilter',
          cell: 'dateCell',
          width: '160px'
        },
        {
          field: 'message',
          filterable: true,
          filter: 'text',
          title: 'Message',
          cell: 'messageCell',
          filterCell: 'messageFilter',
        }
      ]
    }
  },
  watch: {
    date () {
      this.getOrderErrorLogs();
    }
  },
  created () {
    this.kgm_take = 50;
    this.getOrderErrorLogs();
  },
  methods: {
    stopLoading () {
      this.$emit("stopLoading", this.dateTime_dateTime(this.moment()));
    },
    convertMessage (val) {
      if (val.includes('Check in: ')) {
        const startingIndex = val.indexOf('Check in: ');
        const url = val.substr(startingIndex + 'Check in: '.length)
        val = val.replace(url, `<a class="alt-primary-color" target="_blank" href="${url}">${url}</a>`)
      }
      return val
    },
    //api calls
    getOrderErrorLogs () {
      this.loading = true;
      this.axios.post(`/Kibana/GetOrderErrorLogs?installationId=${ this.installationId }`, this.date)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        var hits = response.data.hits.hits;
        if(hits) {
          this.orderErrorLogs = hits.flatMap(a => a._source).map(x => ({message: this.convertMessage(x.environment_message), time: x.Timestamp}))
        }
      })
      .finally(() => {
        this.loading = false;
        this.stopLoading();
      });
    }
  }
}
</script>
<style>
.orderErrorLogsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
.orderErrorLogsList .k-grid  .k-grid-header  .k-header {
    white-space: normal;
}
</style>